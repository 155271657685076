import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Scalar for Long */
  Long: any;
  /** Scalar for Date */
  Date: any;
};

export type ActionsMutation = {
  __typename?: 'ActionsMutation';
  newAction: SomeEntity;
  projectId?: Maybe<Scalars['String']>;
  remove: Scalars['Boolean'];
  update: Scalars['Boolean'];
};


export type ActionsMutationNewActionArgs = {
  whenProvided: Scalars['Date'];
};


export type ActionsMutationRemoveArgs = {
  entityId: Scalars['ID'];
  entityVersion: Scalars['Int'];
};


export type ActionsMutationUpdateArgs = {
  content?: Maybe<ServiceEntryInput>;
  entityId: Scalars['ID'];
  entityVersion: Scalars['Int'];
};

export type ActionsQuery = {
  __typename?: 'ActionsQuery';
  get?: Maybe<ServiceModel>;
  projectId?: Maybe<Scalars['String']>;
  search: ServicesSearchResult;
};


export type ActionsQueryGetArgs = {
  actionId?: Maybe<Scalars['ID']>;
};


export type ActionsQuerySearchArgs = {
  filter?: Maybe<ServiceFilterInput>;
};

export type CustomerContact = {
  __typename?: 'CustomerContact';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNo?: Maybe<Scalars['String']>;
};

export type CustomerContactInput = {
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNo?: Maybe<Scalars['String']>;
};

export type CustomerEntity = {
  __typename?: 'CustomerEntity';
  contacts: Array<CustomerContact>;
  data: CustomerModel;
  id: SomeEntity;
  secrets: Array<CustomerSecret>;
  secretsEx: Array<CustomerSecretEx>;
};

export type CustomerInput = {
  billingModel?: Maybe<Scalars['String']>;
  customerAddress?: Maybe<Scalars['String']>;
  customerCityName?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  daneTechniczne?: Maybe<Scalars['String']>;
  distance?: Maybe<Scalars['Int']>;
  komercjaJest?: Maybe<Scalars['Boolean']>;
  komercjaNotatki?: Maybe<Scalars['String']>;
  nfzAmbulatoryjnaOpiekaSpecjalistyczna?: Maybe<Scalars['Boolean']>;
  nfzLekarz?: Maybe<Scalars['Boolean']>;
  nfzMaFilie?: Maybe<Scalars['Boolean']>;
  nfzMedycynaSzkolna?: Maybe<Scalars['Boolean']>;
  nfzNocnaPomocLekarska?: Maybe<Scalars['Boolean']>;
  nfzNotatki?: Maybe<Scalars['String']>;
  nfzOpiekaDlugoterminowa?: Maybe<Scalars['Boolean']>;
  nfzPielegniarkaSrodowiskowa?: Maybe<Scalars['Boolean']>;
  nfzPolozna?: Maybe<Scalars['Boolean']>;
  nfzProgramyProfilaktyczne?: Maybe<Scalars['Boolean']>;
  nfzPsychiatria?: Maybe<Scalars['Boolean']>;
  nfzRehabilitacja?: Maybe<Scalars['Boolean']>;
  nfzStomatologia?: Maybe<Scalars['Boolean']>;
  nfzSzpitalnictwo?: Maybe<Scalars['Boolean']>;
  nfzTransportSanitarny?: Maybe<Scalars['Boolean']>;
  nfzUmowa?: Maybe<Scalars['Boolean']>;
  nfzZaopatrzenieOrtopedyczne?: Maybe<Scalars['Boolean']>;
  operatorEmail?: Maybe<Scalars['String']>;
  supportStatus?: Maybe<Scalars['String']>;
};

export type CustomerModel = {
  __typename?: 'CustomerModel';
  billingModel?: Maybe<Scalars['String']>;
  customerAddress?: Maybe<Scalars['String']>;
  customerCityName?: Maybe<Scalars['String']>;
  customerName: Scalars['String'];
  daneTechniczne?: Maybe<Scalars['String']>;
  distance?: Maybe<Scalars['Int']>;
  komercjaJest?: Maybe<Scalars['Boolean']>;
  komercjaNotatki?: Maybe<Scalars['String']>;
  nfzAmbulatoryjnaOpiekaSpecjalistyczna?: Maybe<Scalars['Boolean']>;
  nfzLekarz?: Maybe<Scalars['Boolean']>;
  nfzMaFilie?: Maybe<Scalars['Boolean']>;
  nfzMedycynaSzkolna?: Maybe<Scalars['Boolean']>;
  nfzNocnaPomocLekarska?: Maybe<Scalars['Boolean']>;
  nfzNotatki?: Maybe<Scalars['String']>;
  nfzOpiekaDlugoterminowa?: Maybe<Scalars['Boolean']>;
  nfzPielegniarkaSrodowiskowa?: Maybe<Scalars['Boolean']>;
  nfzPolozna?: Maybe<Scalars['Boolean']>;
  nfzProgramyProfilaktyczne?: Maybe<Scalars['Boolean']>;
  nfzPsychiatria?: Maybe<Scalars['Boolean']>;
  nfzRehabilitacja?: Maybe<Scalars['Boolean']>;
  nfzStomatologia?: Maybe<Scalars['Boolean']>;
  nfzSzpitalnictwo?: Maybe<Scalars['Boolean']>;
  nfzTransportSanitarny?: Maybe<Scalars['Boolean']>;
  nfzUmowa?: Maybe<Scalars['Boolean']>;
  nfzZaopatrzenieOrtopedyczne?: Maybe<Scalars['Boolean']>;
  operatorEmail?: Maybe<Scalars['String']>;
  supportStatus?: Maybe<Scalars['String']>;
};

export type CustomerSecret = {
  __typename?: 'CustomerSecret';
  changedWhen: Scalars['String'];
  changedWho: Scalars['String'];
  location: Scalars['String'];
  otpRecoveryKeys?: Maybe<Scalars['String']>;
  otpSecret?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type CustomerSecretEx = {
  __typename?: 'CustomerSecretEx';
  changedWhen: Scalars['String'];
  changedWho: Scalars['String'];
  entityCode?: Maybe<Scalars['String']>;
  entityName?: Maybe<Scalars['String']>;
  location: Scalars['String'];
  otpRecoveryKeys?: Maybe<Scalars['String']>;
  otpSecret?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type CustomerSecretExInput = {
  entityCode?: Maybe<Scalars['String']>;
  entityName?: Maybe<Scalars['String']>;
  location: Scalars['String'];
  otpRecoveryKeys?: Maybe<Scalars['String']>;
  otpSecret?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type CustomerSecretInput = {
  location: Scalars['String'];
  otpRecoveryKeys?: Maybe<Scalars['String']>;
  otpSecret?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type CustomersMutation = {
  __typename?: 'CustomersMutation';
  projectId?: Maybe<Scalars['String']>;
  remove: Scalars['Boolean'];
  reserve: SomeEntity;
  save: SomeEntity;
};


export type CustomersMutationRemoveArgs = {
  id: MyEntity;
};


export type CustomersMutationSaveArgs = {
  contacts: Array<CustomerContactInput>;
  entry: CustomerInput;
  id: MyEntity;
  secrets: Array<CustomerSecretInput>;
  secretsEx: Array<CustomerSecretExInput>;
};

export type CustomersQuery = {
  __typename?: 'CustomersQuery';
  get?: Maybe<CustomerEntity>;
  list: Array<CustomerEntity>;
  projectId?: Maybe<Scalars['String']>;
};


export type CustomersQueryGetArgs = {
  entityId?: Maybe<Scalars['ID']>;
};



export type Mutation = {
  __typename?: 'Mutation';
  Actions: ActionsMutation;
  Customers: CustomersMutation;
  Projects: ProjectsMutation;
};


export type MutationActionsArgs = {
  projectId: Scalars['ID'];
};


export type MutationCustomersArgs = {
  projectId: Scalars['ID'];
};

export type MyEntity = {
  entityId: Scalars['ID'];
  entityVersion: Scalars['Long'];
  projectId: Scalars['ID'];
};

export type PrincipalModel = {
  __typename?: 'PrincipalModel';
  name?: Maybe<Scalars['String']>;
};

export type ProjectEntity = {
  __typename?: 'ProjectEntity';
  entity?: Maybe<SomeEntity>;
  name: Scalars['String'];
};

export type ProjectIdInput = {
  id: Scalars['ID'];
  tag: Scalars['Long'];
};

export type ProjectIdModel = {
  __typename?: 'ProjectIdModel';
  id: Scalars['ID'];
  tag: Scalars['Long'];
};

export type ProjectMutation = {
  __typename?: 'ProjectMutation';
  withOperator: ProjectIdModel;
};


export type ProjectMutationWithOperatorArgs = {
  operatorEmail: Scalars['String'];
};

export type ProjectsMutation = {
  __typename?: 'ProjectsMutation';
  project: ProjectMutation;
  remove: Scalars['Boolean'];
  save: ProjectEntity;
};


export type ProjectsMutationProjectArgs = {
  eid: Scalars['ID'];
  etag: Scalars['ID'];
};


export type ProjectsMutationRemoveArgs = {
  projectId: ProjectIdInput;
};


export type ProjectsMutationSaveArgs = {
  name: Scalars['String'];
};

export type ProjectsQuery = {
  __typename?: 'ProjectsQuery';
  list: Array<Maybe<ProjectEntity>>;
  numberOfProjects: Scalars['Int'];
};


export type ProjectsQueryListArgs = {
  name: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  Actions: ActionsQuery;
  Customers: CustomersQuery;
  getPrincipal: PrincipalModel;
  Projects: ProjectsQuery;
  test: PrincipalModel;
  Users: UsersQuery;
};


export type QueryActionsArgs = {
  projectId: Scalars['ID'];
};


export type QueryCustomersArgs = {
  projectId: Scalars['ID'];
};


export type QueryUsersArgs = {
  projectId: Scalars['ID'];
};

export type ServiceEntryInput = {
  customerId?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  distance?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
  servicemanName?: Maybe<Scalars['String']>;
  /** ISO-8601 */
  whenProvided: Scalars['Date'];
};

export type ServiceFilterInput = {
  /** ISO-8601 */
  from: Scalars['Date'];
  /** ISO-8601 */
  to: Scalars['Date'];
};

/** Query root */
export type ServiceModel = {
  __typename?: 'ServiceModel';
  customer?: Maybe<CustomerEntity>;
  description?: Maybe<Scalars['String']>;
  distance?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
  entityId: Scalars['ID'];
  entityVersion: Scalars['Long'];
  projectId: Scalars['ID'];
  servicemanEmail?: Maybe<Scalars['String']>;
  servicemanName?: Maybe<Scalars['String']>;
  /** ISO-8601 */
  whenProvided: Scalars['Date'];
};

export type ServicesSearchResult = {
  __typename?: 'ServicesSearchResult';
  items: Array<ServiceModel>;
};

export type SomeEntity = {
  __typename?: 'SomeEntity';
  entityId: Scalars['ID'];
  entityVersion: Scalars['Long'];
  projectId: Scalars['ID'];
};

export type User = {
  __typename?: 'User';
  email: Scalars['String'];
  entityId: Scalars['ID'];
};

export type UsersQuery = {
  __typename?: 'UsersQuery';
  projectId?: Maybe<Scalars['String']>;
  search: Array<User>;
};

export type ReserveCustomerMutationVariables = Exact<{
  projectId: Scalars['ID'];
}>;


export type ReserveCustomerMutation = { __typename?: 'Mutation', Customers: { __typename?: 'CustomersMutation', reserve: { __typename?: 'SomeEntity', projectId: string, entityId: string, entityVersion: any } } };

export type SaveCustomerMutationVariables = Exact<{
  projectId: Scalars['ID'];
  id: MyEntity;
  entry: CustomerInput;
  secrets: Array<CustomerSecretInput> | CustomerSecretInput;
  secretsEx: Array<CustomerSecretExInput> | CustomerSecretExInput;
  contacts: Array<CustomerContactInput> | CustomerContactInput;
}>;


export type SaveCustomerMutation = { __typename?: 'Mutation', Customers: { __typename?: 'CustomersMutation', save: { __typename?: 'SomeEntity', projectId: string, entityId: string, entityVersion: any } } };

export type RemoveCustomerMutationVariables = Exact<{
  projectId: Scalars['ID'];
  id: MyEntity;
}>;


export type RemoveCustomerMutation = { __typename?: 'Mutation', Customers: { __typename?: 'CustomersMutation', remove: boolean } };

export type GetCustomerQueryVariables = Exact<{
  projectId: Scalars['ID'];
  entityId: Scalars['ID'];
}>;


export type GetCustomerQuery = { __typename?: 'Query', Customers: { __typename?: 'CustomersQuery', get?: Maybe<{ __typename?: 'CustomerEntity', id: { __typename?: 'SomeEntity', projectId: string, entityId: string, entityVersion: any }, data: { __typename?: 'CustomerModel', operatorEmail?: Maybe<string>, billingModel?: Maybe<string>, supportStatus?: Maybe<string>, distance?: Maybe<number>, customerName: string, customerCityName?: Maybe<string>, customerAddress?: Maybe<string>, nfzUmowa?: Maybe<boolean>, nfzMaFilie?: Maybe<boolean>, nfzLekarz?: Maybe<boolean>, nfzPolozna?: Maybe<boolean>, nfzPielegniarkaSrodowiskowa?: Maybe<boolean>, nfzMedycynaSzkolna?: Maybe<boolean>, nfzTransportSanitarny?: Maybe<boolean>, nfzNocnaPomocLekarska?: Maybe<boolean>, nfzAmbulatoryjnaOpiekaSpecjalistyczna?: Maybe<boolean>, nfzRehabilitacja?: Maybe<boolean>, nfzStomatologia?: Maybe<boolean>, nfzPsychiatria?: Maybe<boolean>, nfzSzpitalnictwo?: Maybe<boolean>, nfzProgramyProfilaktyczne?: Maybe<boolean>, nfzZaopatrzenieOrtopedyczne?: Maybe<boolean>, nfzOpiekaDlugoterminowa?: Maybe<boolean>, nfzNotatki?: Maybe<string>, komercjaJest?: Maybe<boolean>, komercjaNotatki?: Maybe<string>, daneTechniczne?: Maybe<string> }, secrets: Array<{ __typename?: 'CustomerSecret', location: string, username?: Maybe<string>, password?: Maybe<string>, changedWho: string, changedWhen: string, otpSecret?: Maybe<string>, otpRecoveryKeys?: Maybe<string> }>, secretsEx: Array<{ __typename?: 'CustomerSecretEx', location: string, username?: Maybe<string>, password?: Maybe<string>, entityName?: Maybe<string>, entityCode?: Maybe<string>, changedWho: string, changedWhen: string, otpSecret?: Maybe<string>, otpRecoveryKeys?: Maybe<string> }>, contacts: Array<{ __typename?: 'CustomerContact', firstName?: Maybe<string>, lastName?: Maybe<string>, phoneNo?: Maybe<string>, email?: Maybe<string> }> }> } };

export type ListCustomersQueryVariables = Exact<{
  projectId: Scalars['ID'];
}>;


export type ListCustomersQuery = { __typename?: 'Query', Customers: { __typename?: 'CustomersQuery', list: Array<{ __typename?: 'CustomerEntity', id: { __typename?: 'SomeEntity', projectId: string, entityId: string, entityVersion: any }, data: { __typename?: 'CustomerModel', customerName: string, customerCityName?: Maybe<string>, customerAddress?: Maybe<string> }, secretsEx: Array<{ __typename?: 'CustomerSecretEx', location: string, entityCode?: Maybe<string> }> }> } };

export type AvailableProjectsQueryVariables = Exact<{ [key: string]: never; }>;


export type AvailableProjectsQuery = { __typename?: 'Query', Projects: { __typename?: 'ProjectsQuery', list: Array<Maybe<{ __typename?: 'ProjectEntity', name: string, entity?: Maybe<{ __typename?: 'SomeEntity', entityId: string }> }>> } };

export type UserContextQueryVariables = Exact<{ [key: string]: never; }>;


export type UserContextQuery = { __typename?: 'Query', getPrincipal: { __typename?: 'PrincipalModel', name?: Maybe<string> } };

export type GetUsersQueryVariables = Exact<{
  projectId: Scalars['ID'];
}>;


export type GetUsersQuery = { __typename?: 'Query', Users: { __typename?: 'UsersQuery', search: Array<{ __typename?: 'User', email: string }> } };

export type ServiceItemFragment = { __typename?: 'ServiceModel', projectId: string, entityId: string, entityVersion: any, whenProvided: any, servicemanEmail?: Maybe<string>, servicemanName?: Maybe<string>, description?: Maybe<string>, duration?: Maybe<number>, distance?: Maybe<number>, customer?: Maybe<{ __typename?: 'CustomerEntity', id: { __typename?: 'SomeEntity', projectId: string, entityId: string, entityVersion: any }, data: { __typename?: 'CustomerModel', customerName: string } }> };

export type FetchServicesQueryVariables = Exact<{
  projectId: Scalars['ID'];
  from: Scalars['Date'];
  to: Scalars['Date'];
}>;


export type FetchServicesQuery = { __typename?: 'Query', Actions: { __typename?: 'ActionsQuery', search: { __typename?: 'ServicesSearchResult', items: Array<{ __typename?: 'ServiceModel', projectId: string, entityId: string, entityVersion: any, whenProvided: any, servicemanEmail?: Maybe<string>, servicemanName?: Maybe<string>, description?: Maybe<string>, duration?: Maybe<number>, distance?: Maybe<number>, customer?: Maybe<{ __typename?: 'CustomerEntity', id: { __typename?: 'SomeEntity', projectId: string, entityId: string, entityVersion: any }, data: { __typename?: 'CustomerModel', customerName: string } }> }> } } };

export type GetActionQueryVariables = Exact<{
  projectId: Scalars['ID'];
  actionId: Scalars['ID'];
}>;


export type GetActionQuery = { __typename?: 'Query', Actions: { __typename?: 'ActionsQuery', get?: Maybe<{ __typename?: 'ServiceModel', projectId: string, entityId: string, entityVersion: any, whenProvided: any, servicemanEmail?: Maybe<string>, servicemanName?: Maybe<string>, description?: Maybe<string>, duration?: Maybe<number>, distance?: Maybe<number>, customer?: Maybe<{ __typename?: 'CustomerEntity', id: { __typename?: 'SomeEntity', projectId: string, entityId: string, entityVersion: any }, data: { __typename?: 'CustomerModel', customerName: string } }> }> } };

export type NewActionMutationVariables = Exact<{
  projectId: Scalars['ID'];
  when: Scalars['Date'];
}>;


export type NewActionMutation = { __typename?: 'Mutation', Actions: { __typename?: 'ActionsMutation', newAction: { __typename?: 'SomeEntity', projectId: string, entityId: string, entityVersion: any } } };

export type UpdateActionMutationVariables = Exact<{
  projectId: Scalars['ID'];
  entityId: Scalars['ID'];
  entityVersion: Scalars['Int'];
  when: Scalars['Date'];
  what?: Maybe<Scalars['String']>;
  who?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['ID']>;
  duration?: Maybe<Scalars['Int']>;
  distance?: Maybe<Scalars['Int']>;
}>;


export type UpdateActionMutation = { __typename?: 'Mutation', Actions: { __typename?: 'ActionsMutation', update: boolean } };

export type RemoveActionMutationVariables = Exact<{
  projectId: Scalars['ID'];
  entityId: Scalars['ID'];
  entityVersion: Scalars['Int'];
}>;


export type RemoveActionMutation = { __typename?: 'Mutation', Actions: { __typename?: 'ActionsMutation', remove: boolean } };

export const ServiceItemFragmentDoc = gql`
    fragment ServiceItem on ServiceModel {
  projectId
  entityId
  entityVersion
  whenProvided
  servicemanEmail
  servicemanName
  customer {
    id {
      projectId
      entityId
      entityVersion
    }
    data {
      customerName
    }
  }
  description
  duration
  distance
}
    `;
export const ReserveCustomerDocument = gql`
    mutation ReserveCustomer($projectId: ID!) {
  Customers(projectId: $projectId) {
    reserve {
      projectId
      entityId
      entityVersion
    }
  }
}
    `;
export type ReserveCustomerMutationFn = Apollo.MutationFunction<ReserveCustomerMutation, ReserveCustomerMutationVariables>;

/**
 * __useReserveCustomerMutation__
 *
 * To run a mutation, you first call `useReserveCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReserveCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reserveCustomerMutation, { data, loading, error }] = useReserveCustomerMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useReserveCustomerMutation(baseOptions?: Apollo.MutationHookOptions<ReserveCustomerMutation, ReserveCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReserveCustomerMutation, ReserveCustomerMutationVariables>(ReserveCustomerDocument, options);
      }
export type ReserveCustomerMutationHookResult = ReturnType<typeof useReserveCustomerMutation>;
export type ReserveCustomerMutationResult = Apollo.MutationResult<ReserveCustomerMutation>;
export type ReserveCustomerMutationOptions = Apollo.BaseMutationOptions<ReserveCustomerMutation, ReserveCustomerMutationVariables>;
export const SaveCustomerDocument = gql`
    mutation SaveCustomer($projectId: ID!, $id: MyEntity!, $entry: CustomerInput!, $secrets: [CustomerSecretInput!]!, $secretsEx: [CustomerSecretExInput!]!, $contacts: [CustomerContactInput!]!) {
  Customers(projectId: $projectId) {
    save(id: $id, entry: $entry, secrets: $secrets, secretsEx: $secretsEx, contacts: $contacts) {
      projectId
      entityId
      entityVersion
    }
  }
}
    `;
export type SaveCustomerMutationFn = Apollo.MutationFunction<SaveCustomerMutation, SaveCustomerMutationVariables>;

/**
 * __useSaveCustomerMutation__
 *
 * To run a mutation, you first call `useSaveCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCustomerMutation, { data, loading, error }] = useSaveCustomerMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      id: // value for 'id'
 *      entry: // value for 'entry'
 *      secrets: // value for 'secrets'
 *      secretsEx: // value for 'secretsEx'
 *      contacts: // value for 'contacts'
 *   },
 * });
 */
export function useSaveCustomerMutation(baseOptions?: Apollo.MutationHookOptions<SaveCustomerMutation, SaveCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveCustomerMutation, SaveCustomerMutationVariables>(SaveCustomerDocument, options);
      }
export type SaveCustomerMutationHookResult = ReturnType<typeof useSaveCustomerMutation>;
export type SaveCustomerMutationResult = Apollo.MutationResult<SaveCustomerMutation>;
export type SaveCustomerMutationOptions = Apollo.BaseMutationOptions<SaveCustomerMutation, SaveCustomerMutationVariables>;
export const RemoveCustomerDocument = gql`
    mutation RemoveCustomer($projectId: ID!, $id: MyEntity!) {
  Customers(projectId: $projectId) {
    remove(id: $id)
  }
}
    `;
export type RemoveCustomerMutationFn = Apollo.MutationFunction<RemoveCustomerMutation, RemoveCustomerMutationVariables>;

/**
 * __useRemoveCustomerMutation__
 *
 * To run a mutation, you first call `useRemoveCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCustomerMutation, { data, loading, error }] = useRemoveCustomerMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveCustomerMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCustomerMutation, RemoveCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveCustomerMutation, RemoveCustomerMutationVariables>(RemoveCustomerDocument, options);
      }
export type RemoveCustomerMutationHookResult = ReturnType<typeof useRemoveCustomerMutation>;
export type RemoveCustomerMutationResult = Apollo.MutationResult<RemoveCustomerMutation>;
export type RemoveCustomerMutationOptions = Apollo.BaseMutationOptions<RemoveCustomerMutation, RemoveCustomerMutationVariables>;
export const GetCustomerDocument = gql`
    query GetCustomer($projectId: ID!, $entityId: ID!) {
  Customers(projectId: $projectId) {
    get(entityId: $entityId) {
      id {
        projectId
        entityId
        entityVersion
      }
      data {
        operatorEmail
        billingModel
        supportStatus
        distance
        customerName
        customerCityName
        customerAddress
        nfzUmowa
        nfzMaFilie
        nfzLekarz
        nfzPolozna
        nfzPielegniarkaSrodowiskowa
        nfzMedycynaSzkolna
        nfzTransportSanitarny
        nfzNocnaPomocLekarska
        nfzAmbulatoryjnaOpiekaSpecjalistyczna
        nfzRehabilitacja
        nfzStomatologia
        nfzPsychiatria
        nfzSzpitalnictwo
        nfzProgramyProfilaktyczne
        nfzZaopatrzenieOrtopedyczne
        nfzOpiekaDlugoterminowa
        nfzNotatki
        komercjaJest
        komercjaNotatki
        daneTechniczne
      }
      secrets {
        location
        username
        password
        changedWho
        changedWhen
        otpSecret
        otpRecoveryKeys
      }
      secretsEx {
        location
        username
        password
        entityName
        entityCode
        changedWho
        changedWhen
        otpSecret
        otpRecoveryKeys
      }
      contacts {
        firstName
        lastName
        phoneNo
        email
      }
    }
  }
}
    `;

/**
 * __useGetCustomerQuery__
 *
 * To run a query within a React component, call `useGetCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      entityId: // value for 'entityId'
 *   },
 * });
 */
export function useGetCustomerQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, options);
      }
export function useGetCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, options);
        }
export type GetCustomerQueryHookResult = ReturnType<typeof useGetCustomerQuery>;
export type GetCustomerLazyQueryHookResult = ReturnType<typeof useGetCustomerLazyQuery>;
export type GetCustomerQueryResult = Apollo.QueryResult<GetCustomerQuery, GetCustomerQueryVariables>;
export const ListCustomersDocument = gql`
    query ListCustomers($projectId: ID!) {
  Customers(projectId: $projectId) {
    list {
      id {
        projectId
        entityId
        entityVersion
      }
      data {
        customerName
        customerCityName
        customerAddress
      }
      secretsEx {
        location
        entityCode
      }
    }
  }
}
    `;

/**
 * __useListCustomersQuery__
 *
 * To run a query within a React component, call `useListCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCustomersQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListCustomersQuery(baseOptions: Apollo.QueryHookOptions<ListCustomersQuery, ListCustomersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListCustomersQuery, ListCustomersQueryVariables>(ListCustomersDocument, options);
      }
export function useListCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListCustomersQuery, ListCustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListCustomersQuery, ListCustomersQueryVariables>(ListCustomersDocument, options);
        }
export type ListCustomersQueryHookResult = ReturnType<typeof useListCustomersQuery>;
export type ListCustomersLazyQueryHookResult = ReturnType<typeof useListCustomersLazyQuery>;
export type ListCustomersQueryResult = Apollo.QueryResult<ListCustomersQuery, ListCustomersQueryVariables>;
export const AvailableProjectsDocument = gql`
    query AvailableProjects {
  Projects {
    list(name: "") {
      entity {
        entityId
      }
      name
    }
  }
}
    `;

/**
 * __useAvailableProjectsQuery__
 *
 * To run a query within a React component, call `useAvailableProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableProjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAvailableProjectsQuery(baseOptions?: Apollo.QueryHookOptions<AvailableProjectsQuery, AvailableProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AvailableProjectsQuery, AvailableProjectsQueryVariables>(AvailableProjectsDocument, options);
      }
export function useAvailableProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AvailableProjectsQuery, AvailableProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AvailableProjectsQuery, AvailableProjectsQueryVariables>(AvailableProjectsDocument, options);
        }
export type AvailableProjectsQueryHookResult = ReturnType<typeof useAvailableProjectsQuery>;
export type AvailableProjectsLazyQueryHookResult = ReturnType<typeof useAvailableProjectsLazyQuery>;
export type AvailableProjectsQueryResult = Apollo.QueryResult<AvailableProjectsQuery, AvailableProjectsQueryVariables>;
export const UserContextDocument = gql`
    query UserContext {
  getPrincipal {
    name
  }
}
    `;

/**
 * __useUserContextQuery__
 *
 * To run a query within a React component, call `useUserContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserContextQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserContextQuery(baseOptions?: Apollo.QueryHookOptions<UserContextQuery, UserContextQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserContextQuery, UserContextQueryVariables>(UserContextDocument, options);
      }
export function useUserContextLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserContextQuery, UserContextQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserContextQuery, UserContextQueryVariables>(UserContextDocument, options);
        }
export type UserContextQueryHookResult = ReturnType<typeof useUserContextQuery>;
export type UserContextLazyQueryHookResult = ReturnType<typeof useUserContextLazyQuery>;
export type UserContextQueryResult = Apollo.QueryResult<UserContextQuery, UserContextQueryVariables>;
export const GetUsersDocument = gql`
    query getUsers($projectId: ID!) {
  Users(projectId: $projectId) {
    search {
      email
    }
  }
}
    `;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const FetchServicesDocument = gql`
    query FetchServices($projectId: ID!, $from: Date!, $to: Date!) {
  Actions(projectId: $projectId) {
    search(filter: {from: $from, to: $to}) {
      items {
        ...ServiceItem
      }
    }
  }
}
    ${ServiceItemFragmentDoc}`;

/**
 * __useFetchServicesQuery__
 *
 * To run a query within a React component, call `useFetchServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchServicesQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useFetchServicesQuery(baseOptions: Apollo.QueryHookOptions<FetchServicesQuery, FetchServicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchServicesQuery, FetchServicesQueryVariables>(FetchServicesDocument, options);
      }
export function useFetchServicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchServicesQuery, FetchServicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchServicesQuery, FetchServicesQueryVariables>(FetchServicesDocument, options);
        }
export type FetchServicesQueryHookResult = ReturnType<typeof useFetchServicesQuery>;
export type FetchServicesLazyQueryHookResult = ReturnType<typeof useFetchServicesLazyQuery>;
export type FetchServicesQueryResult = Apollo.QueryResult<FetchServicesQuery, FetchServicesQueryVariables>;
export const GetActionDocument = gql`
    query GetAction($projectId: ID!, $actionId: ID!) {
  Actions(projectId: $projectId) {
    get(actionId: $actionId) {
      ...ServiceItem
    }
  }
}
    ${ServiceItemFragmentDoc}`;

/**
 * __useGetActionQuery__
 *
 * To run a query within a React component, call `useGetActionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActionQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      actionId: // value for 'actionId'
 *   },
 * });
 */
export function useGetActionQuery(baseOptions: Apollo.QueryHookOptions<GetActionQuery, GetActionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActionQuery, GetActionQueryVariables>(GetActionDocument, options);
      }
export function useGetActionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActionQuery, GetActionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActionQuery, GetActionQueryVariables>(GetActionDocument, options);
        }
export type GetActionQueryHookResult = ReturnType<typeof useGetActionQuery>;
export type GetActionLazyQueryHookResult = ReturnType<typeof useGetActionLazyQuery>;
export type GetActionQueryResult = Apollo.QueryResult<GetActionQuery, GetActionQueryVariables>;
export const NewActionDocument = gql`
    mutation newAction($projectId: ID!, $when: Date!) {
  Actions(projectId: $projectId) {
    newAction(whenProvided: $when) {
      projectId
      entityId
      entityVersion
    }
  }
}
    `;
export type NewActionMutationFn = Apollo.MutationFunction<NewActionMutation, NewActionMutationVariables>;

/**
 * __useNewActionMutation__
 *
 * To run a mutation, you first call `useNewActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newActionMutation, { data, loading, error }] = useNewActionMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      when: // value for 'when'
 *   },
 * });
 */
export function useNewActionMutation(baseOptions?: Apollo.MutationHookOptions<NewActionMutation, NewActionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NewActionMutation, NewActionMutationVariables>(NewActionDocument, options);
      }
export type NewActionMutationHookResult = ReturnType<typeof useNewActionMutation>;
export type NewActionMutationResult = Apollo.MutationResult<NewActionMutation>;
export type NewActionMutationOptions = Apollo.BaseMutationOptions<NewActionMutation, NewActionMutationVariables>;
export const UpdateActionDocument = gql`
    mutation updateAction($projectId: ID!, $entityId: ID!, $entityVersion: Int!, $when: Date!, $what: String, $who: String, $customerId: ID, $duration: Int, $distance: Int) {
  Actions(projectId: $projectId) {
    update(entityId: $entityId, entityVersion: $entityVersion, content: {servicemanName: $who, whenProvided: $when, customerId: $customerId, description: $what, duration: $duration, distance: $distance})
  }
}
    `;
export type UpdateActionMutationFn = Apollo.MutationFunction<UpdateActionMutation, UpdateActionMutationVariables>;

/**
 * __useUpdateActionMutation__
 *
 * To run a mutation, you first call `useUpdateActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActionMutation, { data, loading, error }] = useUpdateActionMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      entityId: // value for 'entityId'
 *      entityVersion: // value for 'entityVersion'
 *      when: // value for 'when'
 *      what: // value for 'what'
 *      who: // value for 'who'
 *      customerId: // value for 'customerId'
 *      duration: // value for 'duration'
 *      distance: // value for 'distance'
 *   },
 * });
 */
export function useUpdateActionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateActionMutation, UpdateActionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateActionMutation, UpdateActionMutationVariables>(UpdateActionDocument, options);
      }
export type UpdateActionMutationHookResult = ReturnType<typeof useUpdateActionMutation>;
export type UpdateActionMutationResult = Apollo.MutationResult<UpdateActionMutation>;
export type UpdateActionMutationOptions = Apollo.BaseMutationOptions<UpdateActionMutation, UpdateActionMutationVariables>;
export const RemoveActionDocument = gql`
    mutation removeAction($projectId: ID!, $entityId: ID!, $entityVersion: Int!) {
  Actions(projectId: $projectId) {
    remove(entityId: $entityId, entityVersion: $entityVersion)
  }
}
    `;
export type RemoveActionMutationFn = Apollo.MutationFunction<RemoveActionMutation, RemoveActionMutationVariables>;

/**
 * __useRemoveActionMutation__
 *
 * To run a mutation, you first call `useRemoveActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeActionMutation, { data, loading, error }] = useRemoveActionMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      entityId: // value for 'entityId'
 *      entityVersion: // value for 'entityVersion'
 *   },
 * });
 */
export function useRemoveActionMutation(baseOptions?: Apollo.MutationHookOptions<RemoveActionMutation, RemoveActionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveActionMutation, RemoveActionMutationVariables>(RemoveActionDocument, options);
      }
export type RemoveActionMutationHookResult = ReturnType<typeof useRemoveActionMutation>;
export type RemoveActionMutationResult = Apollo.MutationResult<RemoveActionMutation>;
export type RemoveActionMutationOptions = Apollo.BaseMutationOptions<RemoveActionMutation, RemoveActionMutationVariables>;